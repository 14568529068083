
import { API } from 'aws-amplify'
import React, { useState, useEffect } from 'react';
import { useLocation, BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider, Form, useField } from 'formik';
import './styles.css';
import * as Yup from 'yup';
import CustomSelect from './CustomSelect'
import ReCAPTCHA from "react-google-recaptcha";


const options = [
  { value: 'iPreFlight Genesis Pro', label: 'iPreFlight Genesis Pro' },
  { value: 'RocketRoute Flight Planning', label: 'RocketRoute Flight Planning' },
  { value: 'ChartData', label: 'ChartData' },
  { value: 'Voyager', label: 'Voyager' },
  { value: 'FlyQ', label: 'FlyQ' },
]

var recaptchaValue = '';

const TextInputLiveFeedback = ({ label, helpText, ...props }) => {
  const [field, meta] = useField(props);

  // Show inline feedback if EITHER
  // - the input is focused AND value is longer than 2 characters
  // - or, the has been visited (touched === true)
  const [didFocus, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  
  const showFeedback =
    (!!didFocus && field.value.trim().length > 2) || meta.touched;

  return (
    <div
      className={`form-control ${
        showFeedback ? (meta.error ? 'invalid' : 'valid') : ''
      }`}
    >
      <div className="flex items-center space-between">
        <label htmlFor={props.id}>{label}</label>{' '}
        {showFeedback ? (
          <div
            id={`${props.id}-feedback`}
            aria-live="polite"
            className="feedback text-sm"
          >
            {meta.error ? meta.error : '✓'}
          </div>
        ) : null}
      </div>
      <input
        {...props}
        {...field}
        aria-describedby={`${props.id}-feedback ${props.id}-help`}
        onFocus={handleFocus}
      />
      <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
        {helpText}
      </div>
    </div>
  );
};

var verifyCallback = function (response) {
  console.log("verifyCallbackFired");
  console.log(response);
  recaptchaValue = response;
};

const Home = () => {
  let query = useQuery();
  
  const [toNext, setToNext] = useState(false)
  let navigate = useNavigate();
  
  useEffect(() => { 
    console.log("useEffectFired");
    if (toNext) {
       // console.log('Navigate');
      window.location.replace('/thankyou.html');
      setToNext(false);
    }
  
   }, [navigate, toNext]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      emailaddress: '',
      phone: '',
      leadsource: query.get("leadsource"),
      recaptcha: '',
      product: 'iPreFlight Genesis Pro'
    },
    
    onSubmit: async (values) => {
      values.recaptcha=recaptchaValue;
      //console.log(values);
      const myInit = {
        body: {
          values
        },
      };
     

     // alert(JSON.stringify(myInit, null, 2));
      setIsSubmitting(true);
      const apiData = await API.post('maincontactapi', '/contact', myInit);
      //alert(JSON.stringify(apiData, null, 2));
      setIsSubmitting(false)  
      setToNext(true);
    },
    validate: (values) => {
      const errors = {};
      if (!recaptchaValue) {
          errors.recaptcha = 'Required';
      }
      return errors;
    }, 
    validationSchema: Yup.object({
      firstname: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('First Name is required')
        .matches(
          /^[a-zA-Z0-9]+$/,
          'Cannot contain special characters or spaces'
        ),
      lastname: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('First Name is required')
        .matches(
          /^[a-zA-Z0-9]+$/,
          'Cannot contain special characters or spaces'
        ),
       emailaddress: Yup.string().email('Invalid email format').required('Required'),
       phone: Yup.string()
       .required("Phone is required")
       .matches(
        /^[0-9+()]+$/,
        //  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
         "Phone number is not valid"
       )
    }),
  });
 
return (
  <FormikProvider value={formik}>
    <Form>
     <TextInputLiveFeedback
        label="Email Address"
        id="emailaddress"
        name="emailaddress"
        helpText={query.get("name")}
        type="text"
      />
     <TextInputLiveFeedback
        label="First Name"
        id="firstname"
        name="firstname"
        helpText="Required"
        type="text"
      />
      <TextInputLiveFeedback
        label="Last Name"
        id="lastname"
        name="lastname"
        helpText="Required"
        type="text"
      />
      <TextInputLiveFeedback
        label="Phone"
        id="phone"
        name="phone"
        helpText="Required"
        type="text"
      />
        <br/>
        <label>Product Type</label> 
        <CustomSelect
             className='input'
             onChange={value=>formik.setFieldValue('product',value.value)}
             value={formik.values.product}
             options={options}
          />

      <div>
        <br></br>
           <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="light"
            sitekey="6LfA2gwcAAAAAIhX80xXxqW9fF_unmDBVUwV56Db"
            onChange={verifyCallback}
            asyncScriptOnLoad={() => { console.log("done loading!"); }}
          />     
         <br></br>
        
         <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-1">
              {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Submit
         </button>
      </div>
    </Form>
   
  </FormikProvider>
)};


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  
  return (
  
    <div className="app">
    {/* <img src="combinedwlines.png" width="500" height="85" alt="APG | RocketRoute | Seattle Avionics"></img>   */}
    <img src="combinedwlines.png" width="100%" alt="APG | RocketRoute | Seattle Avionics"></img>  
    <h2>I want to find out more</h2>
    <div className="contact">
       <Router>
           <Home />
       </Router>
    </div>  
    </div>

  );
}



export default App;
//export default withAuthenticator(App)
//rrapikey=6LfA2gwcAAAAAIhX80xXxqW9fF_unmDBVUwV56Db
//apgapikey=6Ld53gwcAAAAAHbYCOiTNRW3PwhProepm55ZrEFy
//ssavapikey=6Lee3QwcAAAAAOJf3PA_9l6ZMKfSVBIe5umf8OeW
//localhost=6Le2nREUAAAAALYuOv7X9Fe3ysDmOmghtj0dbCKW
//test=6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI